import { MARKS } from '@contentful/rich-text-types';
import classNames from 'classnames';
import { graphql, PageProps } from 'gatsby';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import React, { useCallback, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import {
  ContactForm,
  NarrowCenteredContainer,
  PositionIcon,
  RecruitmentProcess,
  SkillRating
} from '../components';
import { iconPlaceImg } from '../images';
import { Career } from '../models';

const CareerTemplate = ({ pageContext }: PageProps) => {
  const { t } = useTranslation();
  const [applying, setApplying] = useState(false);
  const careerDetailsRef = useRef<HTMLElement | null>(null);
  const career: Career = pageContext as any;

  const applyButton = useCallback(
    (className?: string) => (
      <a
        className={classNames(
          'inline-block button-primary-inverted',
          className
        )}
        href="#applyForm"
        onClick={() => setApplying(true)}
      >
        {t('shared.apply')}
      </a>
    ),
    [t, setApplying]
  );

  return (
    <>
      <Helmet>
        <title>{t('career.page-title', { careerName: career.name })}</title>
      </Helmet>
      <section className="text-white bg-cover relative" id="banner">
        <div className="bg-secondary h-[calc(100%+var(--full-menu-height))] w-full absolute -z-50 top-[calc(-1*var(--full-menu-height))]" />
        <NarrowCenteredContainer className="pt-4 md:pt-28 pb-8 md:pb-20 md:text-center">
          <a
            className="md:hidden h-8 w-8 rounded-lg bg-opacity-20 bg-white text-white flex justify-center items-center"
            href="/careers"
          >
            <span className="material-symbols-outlined text-lg">
              arrow_back
            </span>
          </a>
          <p className="text-primary md:font-secondary md:font-black md:uppercase text-[.625rem] md:text-2xl mb-2 md:mb-8 flex items-center md:justify-center mt-8 md:mt-0">
            <PositionIcon
              className="inline-block mr-1 md:mr-2 h-3 md:h-6 w-3 md:w-6"
              position={career.position}
            />
            {career.position}
          </p>
          <h1 className="md:font-secondary md:font-black md:uppercase text-lg md:text-6xl leading-tight mb-2 md:mb-11">
            {career.name}
          </h1>
          <p className="text-[.625rem] md:text-[2.25rem] leading-snug flex items-center md:justify-center">
            <i
              className="bg-contain bg-no-repeat bg-right  h-3 md:h-8 w-3 md:w-8 inline-block mr-1 md:mr-2"
              style={{ backgroundImage: `url("${iconPlaceImg}")` }}
            />
            {career.location}
          </p>
          <div className="md:hidden mt-4 text-primary font-semibold">
            {career.salary}
          </div>
          {applyButton('mt-10')}
        </NarrowCenteredContainer>
      </section>
      <section
        className="md:pt-16 md:pb-28 md:bg-[color:rgba(var(--color-footer-text))]"
        id="careerDetails"
        ref={careerDetailsRef}
      >
        <NarrowCenteredContainer>
          <div className="bg-white rounded-lg px-0 md:px-12 py-7 md:py-8">
            <h3 className="text-lg md:text-2xl font-secondary text-header">
              {t('career.tech-stack')}
            </h3>
            <div className="flex flex-wrap gap-x-20 gap-y-10 mt-6">
              {career.skills.map((skill) => (
                <SkillRating
                  className="flex-shrink-0"
                  key={skill.name}
                  skill={skill}
                  levelName={t(`career.level-names.${skill.rating}`)}
                />
              ))}
            </div>
            <h3 className="text-2xl font-secondary text-header mt-16">
              {t('shared.description')}
            </h3>
            <div className="prose max-w-none mt-4 prose-ul:m-0 prose-ul:pl-10 prose-li:-my-4 prose-li:marker:text-header">
              {renderRichText(career.description, {
                renderMark: {
                  [MARKS.BOLD]: (text) => (
                    <strong className="font-bold">{text}</strong>
                  ),
                  [MARKS.ITALIC]: (text) => <em>{text}</em>,
                  [MARKS.UNDERLINE]: (text) => (
                    <span className="underline">{text}</span>
                  ),
                },
              })}
            </div>
            <hr className="hidden md:block mt-16 mb-6" />
            <div className="hidden md:flex justify-between items-center">
              <div className="text-primary text-2xl">{career.salary}</div>
              {applyButton()}
            </div>
          </div>
        </NarrowCenteredContainer>
      </section>
      {applying && (
        <section className="padded-section" id="applyForm">
          <NarrowCenteredContainer>
            <ContactForm
              mode="career"
              subject={`${career.name} [${career.id}]`}
              onFormSubmitted={() => {
                setApplying(false);
                careerDetailsRef.current?.scrollIntoView({
                  behavior: 'smooth',
                });
              }}
            />
          </NarrowCenteredContainer>
        </section>
      )}
      <RecruitmentProcess />
    </>
  );
};

export default CareerTemplate;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
